import * as React from "react";
import Timeline from "@mui/lab/Timeline";
import TimelineItem from "@mui/lab/TimelineItem";
import TimelineSeparator from "@mui/lab/TimelineSeparator";
import TimelineConnector from "@mui/lab/TimelineConnector";
import TimelineContent from "@mui/lab/TimelineContent";
import TimelineDot from "@mui/lab/TimelineDot";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import { makeStyles } from "@material-ui/core/styles";

import styles from "assets/jss/material-kit-react/views/landingPageSections/productStyle.js";
const useStyles = makeStyles(styles);

export default function OutlinedTimeline() {
  const classes = useStyles();
  return (
    <div className={classes.section}>
      <GridContainer justify="center">
        <GridItem xs={12} sm={12} md={8}>
          <h2 className={classes.title}>Roadmap</h2>
        </GridItem>
      </GridContainer>
      <Timeline position="alternate">
        <TimelineItem>
          <TimelineSeparator>
            <TimelineDot variant="outlined" color="success" />
            <TimelineConnector />
          </TimelineSeparator>
          <TimelineContent style={{ color: "black" }}>
            <span>
              <b> DROP: </b>{" "}
            </span>
            <br></br>
            Our initial focus is getting as much people a Pinniped from our
            collection. Our NFT is aimed to be fun, and everybody should have
            the possibility to own one!
          </TimelineContent>
        </TimelineItem>
        <TimelineItem>
          <TimelineSeparator>
            <TimelineDot variant="outlined" color="warning" />
            <TimelineConnector />
          </TimelineSeparator>
          <TimelineContent style={{ color: "black" }}>
            <span>
              <b> GROWTH: </b>{" "}
            </span>
            <br></br>
            We{"'"}re settings up a community fund and seeding it with 20% of
            royalties to provide marketing and growth mechanics.
          </TimelineContent>
        </TimelineItem>
        <TimelineItem>
          <TimelineSeparator>
            <TimelineDot variant="outlined" color="secondary" />
            <TimelineConnector />
          </TimelineSeparator>
          <TimelineContent style={{ color: "black" }}>
            <span>
              <b> UTILITY: </b>{" "}
            </span>
            <br></br>
            We don{"'"}t stop there. In the future, you will be able to stake
            your NFT{"'"}s, get custom merchandise and get access to different
            airdrops. Not only from us, but we are working on collaboration
            projects which will be announced soon!
          </TimelineContent>
        </TimelineItem>
        <TimelineItem>
          <TimelineSeparator>
            <TimelineDot variant="outlined" color="info" />
            <TimelineConnector />
          </TimelineSeparator>
          <TimelineContent style={{ color: "black" }}>
            <span>
              <b> FUTURE COLLECTIONS: </b>{" "}
            </span>
            <br></br>
            There are other drops and collections planned! We want to include
            all Pinnipeds, and give them their chance to shine on the Solana
            Blockchain. Those future collections will add more value and utility
            to the Pinniped universe. So it{"'"}s better to get in early!
          </TimelineContent>
        </TimelineItem>
        <TimelineItem>
          <TimelineSeparator>
            <TimelineDot variant="outlined" color="error" />
            <TimelineConnector />
          </TimelineSeparator>
          <TimelineContent style={{ color: "black" }}>
            <span>
              <b> CHARITY WORK: </b>{" "}
            </span>
            <br></br>
            Additional to the already low minting fee, we are creating a charity
            fund, to support real life pinnipeds!
          </TimelineContent>
        </TimelineItem>
      </Timeline>
    </div>
  );
}
