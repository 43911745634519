import React, { useRef } from "react";
import { useEffect, useState } from "react";
import classNames from "classnames";
import { CircularProgress, Snackbar } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { useAnchorWallet } from "@solana/wallet-adapter-react";
import { LAMPORTS_PER_SOL } from "@solana/web3.js";
import PropTypes from "prop-types";
import Alert from "@material-ui/lab/Alert";
import * as anchor from "@project-serum/anchor";

import Logo from "assets/img/header.png";
import Footer from "components/Footer/Footer.js";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Button from "components/CustomButtons/Button.js";

import Parallax from "components/Parallax/Parallax.js";

import styles from "assets/jss/material-kit-react/views/landingPage.js";

import {
  CandyMachine,
  awaitTransactionSignatureConfirmation,
  getCandyMachineState,
  mintOneToken,
  shortenAddress,
} from "../../candy-machine";

// Sections for this page
import ProductSection from "./Sections/ProductSection.js";
import TeamSection from "./Sections/TeamSection.js";
import RoadMap from "./Sections/RoadmapSection";
import HeaderLinks from "../../components/Header/HeaderLinks";
import Header from "../../components/Header/Header";
import { WalletDialogButton } from "@solana/wallet-adapter-material-ui";

const useStyles = makeStyles(styles);
const dashboardRoutes = [];

LandingPage.propTypes = {
  candyMachineId: anchor.web3.PublicKey,
  config: anchor.web3.PublicKey,
  connection: anchor.web3.Connection,
  startDate: PropTypes.number,
  treasury: anchor.web3.PublicKey,
  txTimeout: PropTypes.number,
};

export default function LandingPage(props) {
  const [balance, setBalance] = useState();
  const [isActive, setIsActive] = useState(true); // true when countdown completes
  const [isSoldOut, setIsSoldOut] = useState(false); // true when items remaining is zero
  const [isMinting, setIsMinting] = useState(false); // true when user got to press MINT

  const [itemsAvailable, setItemsAvailable] = useState(0);
  const [itemsRedeemed, setItemsRedeemed] = useState(0);
  const [itemsRemaining, setItemsRemaining] = useState(0);

  const [startDate, setStartDate] = useState(new Date(props.startDate));
  const [candyMachine, setCandyMachine] = useState();

  const classes = useStyles();
  const imageClasses = classNames(classes.imgFluid);

  const wallet = useAnchorWallet();

  const [alertState, setAlertState] = useState({
    open: false,
    message: "",
    severity: undefined,
  });

  const refreshCandyMachineState = () => {
    (async () => {
      if (!wallet) return;

      const {
        candyMachine,
        goLiveDate,
        itemsAvailable,
        itemsRemaining,
        itemsRedeemed,
      } = await getCandyMachineState(
        wallet,
        props.candyMachineId,
        props.connection
      );

      setItemsAvailable(itemsAvailable);
      setItemsRemaining(itemsRemaining);
      setItemsRedeemed(itemsRedeemed);

      setIsSoldOut(itemsRemaining === 0);
      setStartDate(goLiveDate);
      setCandyMachine(candyMachine);
    })();
  };

  useEffect(() => {
    (async () => {
      if (wallet) {
        const balance = await props.connection.getBalance(wallet.publicKey);
        setBalance(balance / LAMPORTS_PER_SOL);
      }
    })();
  }, [wallet, props.connection]);

  useEffect(refreshCandyMachineState, [
    wallet,
    props.candyMachineId,
    props.connection,
  ]);

  const onMint = async () => {
    try {
      setIsMinting(true);
      if (wallet && candyMachine?.program) {
        const mintTxId = await mintOneToken(
          candyMachine,
          props.config,
          wallet.publicKey,
          props.treasury
        );

        const status = await awaitTransactionSignatureConfirmation(
          mintTxId,
          props.txTimeout,
          props.connection,
          "singleGossip",
          false
        );

        if (!status?.err) {
          setAlertState({
            open: true,
            message: "Congratulations! Mint succeeded!",
            severity: "success",
          });
        } else {
          setAlertState({
            open: true,
            message: "Mint failed! Please try again!",
            severity: "error",
          });
        }
      }
    } catch (error) {
      // TODO: blech:
      let message = error.msg || "Minting failed! Please try again!";
      if (!error.msg) {
        if (error.message.indexOf("0x138")) {
        } else if (error.message.indexOf("0x137")) {
          message = `SOLD OUT!`;
        } else if (error.message.indexOf("0x135")) {
          message = `Insufficient funds to mint. Please fund your wallet.`;
        }
      } else {
        if (error.code === 311) {
          message = `SOLD OUT!`;
          setIsSoldOut(true);
        } else if (error.code === 312) {
          message = `Minting period hasn't started yet.`;
        }
      }

      setAlertState({
        open: true,
        message,
        severity: "error",
      });
    } finally {
      if (wallet) {
        const balance = await props.connection.getBalance(wallet.publicKey);
        setBalance(balance / LAMPORTS_PER_SOL);
      }
      setIsMinting(false);
      refreshCandyMachineState();
    }
  };

  const scrollTo = (ref) => {
    if (ref && ref.current /* + other conditions */) {
      ref.current.scrollIntoView({ behavior: "smooth", block: "start" });
    }
  };

  return (
    <div>
      <Header
        color="transparent"
        routes={dashboardRoutes}
        brand=""
        rightLinks={<HeaderLinks wallet={wallet} />}
        fixed
        changeColorOnScroll={{
          height: 400,
          color: "white",
        }}
      />
      <Parallax filter>
        <div className={classes.container}>
          <GridContainer>
            <GridItem
              style={{
                width: "100%",
                position: "relative",
                minHeight: "1px",
                paddingLeft: "15px",
                paddingRight: "15px",
              }}
              xs={12}
              sm={12}
              md={6}
            >
              <h1 className={classes.title}>Pinnipeds on Solana</h1>
              <h3>
                1{"'"}000 unique Seals are looking for a new home. Join the
                community and get Solanas most affordable NFT.
              </h3>
              <br />
              {!wallet ? (
                <WalletDialogButton>Connect Wallet</WalletDialogButton>
              ) : (
                <Button
                  onClick={onMint}
                  disabled={isSoldOut || isMinting || !isActive}
                  color="primary"
                  size="lg"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  {isSoldOut ? (
                    "SOLD OUT"
                  ) : isActive ? (
                    isMinting ? (
                      <CircularProgress />
                    ) : (
                      "MINT"
                    )
                  ) : (
                    "Not Available"
                  )}
                </Button>
              )}
            </GridItem>
            <GridItem xs={12} sm={12} md={6}>
              <img
                style={{
                  width: "100%",
                  position: "relative",
                  minHeight: "1px",
                  paddingLeft: "15px",
                  paddingRight: "15px",
                  marginLeft: "auto",
                }}
                src={Logo}
                alt="..."
                className={imageClasses}
              />
            </GridItem>
          </GridContainer>
        </div>
      </Parallax>
      <div className={classNames(classes.main, classes.mainRaised)}>
        <div className={classes.container}>
          <ProductSection
            itemsAvailable={itemsAvailable}
            itemsRemaining={itemsRemaining}
            isSoldOut={isSoldOut}
            isMinting={isMinting}
            isActive={isActive}
            onMint={onMint}
            wallet={wallet}
          />
          <Snackbar
            open={alertState.open}
            autoHideDuration={6000}
            onClose={() => setAlertState({ ...alertState, open: false })}
          >
            <Alert
              onClose={() => setAlertState({ ...alertState, open: false })}
              severity={alertState.severity}
            >
              {alertState.message}
            </Alert>
          </Snackbar>
          <RoadMap />
          <TeamSection />
        </div>
      </div>
      <Footer id="product" />
    </div>
  );
}
