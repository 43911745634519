import React from "react";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import { CircularProgress } from "@material-ui/core";

// @material-ui/icons
import Money from "@material-ui/icons/AttachMoney";
import Storefront from "@material-ui/icons/Storefront";
// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import InfoArea from "components/InfoArea/InfoArea.js";
import Button from "components/CustomButtons/Button.js";
import CircularProgressWithLabel from "components/CircularProgressWithLabel";
import { WalletDialogButton } from "@solana/wallet-adapter-material-ui";

import styles from "assets/jss/material-kit-react/views/landingPageSections/productStyle.js";
import PropTypes from "prop-types";

const useStyles = makeStyles(styles);

ProductSection.propTypes = {
  isSoldOut: PropTypes.bool,
  isMinting: PropTypes.bool,
  isActive: PropTypes.bool,
  onMint: PropTypes.func,
  wallet: PropTypes.object,
  itemsRemaining: PropTypes.number,
  itemsAvailable: PropTypes.number,
};

export default function ProductSection(props) {
  const classes = useStyles();
  return (
    <div className={classes.section}>
      <GridContainer justify="center">
        <GridItem xs={12} sm={12} md={8}>
          <h2 className={classes.title}>Seal Collection Available NOW!</h2>
          <h4 className={classes.description}>
            Our first collection features 1000 unique generated seals. Give one
            of our water dogs a new home!
          </h4>
        </GridItem>
      </GridContainer>
      <div>
        <GridContainer>
          <GridItem xs={12} sm={12} md={4}>
            <InfoArea
              title="Price: 0.1 SOL"
              description="NFTs should be for everyone, we don't sell for profit, we
                  sell for fun. With this price tag, you help us pay the minting fee, as well as to pay the artist."
              icon={Money}
              iconColor="success"
              vertical
            />
          </GridItem>
          <GridItem xs={12} sm={12} md={4}>
            <div className={classes.infoArea}>
              <div style={{ marginTop: "30px" }}>
                <CircularProgressWithLabel value={90} />
              </div>
              <div
                style={{ marginTop: "40px" }}
                className={classes.descriptionWrapper}
              >
                <h3 className={classes.title}>Limited</h3>
                <h5 className={classes.description}>
                  Get one as long as they are available. Check out our Twitter
                  for status updates! Also, you can always check secondary
                  markets.
                </h5>
              </div>
            </div>
          </GridItem>

          <GridItem xs={12} sm={12} md={4}>
            <InfoArea
              title="Get your Merch"
              description="We are big fans of merch. With your NFT, you will have the possibility to order custom merch, featuring your personal Pinniped."
              icon={Storefront}
              iconColor="danger"
              vertical
            />
          </GridItem>
        </GridContainer>
        <GridContainer justify="center">
          <GridItem xs={12} sm={12} md={8} style={{ marginTop: "50px" }}>
            {!props.wallet ? (
              <WalletDialogButton>Connect Wallet</WalletDialogButton>
            ) : (
              <Button
                onClick={props.onMint}
                disabled={props.isSoldOut || props.isMinting || !props.isActive}
                color="primary"
                size="lg"
                target="_blank"
                rel="noopener noreferrer"
              >
                {props.isSoldOut ? (
                  "SOLD OUT"
                ) : props.isActive ? (
                  props.isMinting ? (
                    <CircularProgress />
                  ) : (
                    "MINT"
                  )
                ) : (
                  "Not Available"
                )}
              </Button>
            )}
          </GridItem>
        </GridContainer>
      </div>
    </div>
  );
}
